<template>
  <div class="controller">
    <h1>{{ $t('Your Controllers') }} {{ displayLimit }}</h1>

    <template v-if="loading">
      <n-card>
        <n-space vertical>
          <n-skeleton size="medium" text style="width: 40%" />
          <n-grid :cols="5">
            <n-gi>
              <n-skeleton size="medium" text style="width: 35%" />
            </n-gi>
            <n-gi>
              <n-skeleton size="medium" text style="width: 35%" />
            </n-gi>
          </n-grid>
        </n-space>
      </n-card>
    </template>

    <template v-else-if="controllers && controllers.length">
      <n-space vertical>
        <n-card v-for="controller in controllers" :key="controller.serialNumber">
          <n-page-header :subtitle="controller.name">
            <n-grid :cols="5">
              <n-gi>
                <n-statistic :label="$t('Cloud connected')" :value="controller.connected ? $t('Yes') : $t('No')" />
              </n-gi>
              <n-gi>
                <n-statistic :label="$t('Remote Access')" :value="controller.remoteAccess ? $t('Yes') : $t('No')" />
              </n-gi>
            </n-grid>
            <template #title>
              {{ controller.serialNumber }}
            </template>
            <template #avatar>
              <n-avatar :src="`${publicPath}HUB.svg`" color="#fff" />
            </template>
            <template #extra>
              <n-button @click="unregister(controller.serialNumber)">
                <template #icon>
                  <n-icon>
                    <link-icon />
                  </n-icon>
                </template>
                {{ $t('Unregister') }}
              </n-button>
            </template>
          </n-page-header>
        </n-card>
      </n-space>
    </template>

    <n-empty v-else :description="$t('No controllers registered.')" />
  </div>
</template>

<script>
import {get, post} from 'aws-amplify/api';
import {fetchUserAttributes} from 'aws-amplify/auth';
import {useLoadingBar, useNotification, useDialog} from 'naive-ui';
import {Unlink as LinkIcon} from '@vicons/ionicons5';

export default {
  name: 'ControllerPanel',
  components: {LinkIcon},
  data() {
    return {
      loading: true,
      publicPath: process.env.BASE_URL,
      limits: {},
      controllers: []
    };
  },
  computed: {
    displayLimit() {
      if (this.limits.current === undefined) return '';
      return '(' + this.limits.current + '/' + this.limits.limit + ')';
    }
  },
  created() {
    this.dialog = useDialog();
    this.loadingBar = useLoadingBar();
    this.notification = useNotification();
  },
  mounted() {
    this.getControllers();
    this.getLimit();
  },
  methods: {
    unregister(serial) {
      this.dialog.error({
        title: this.$t('Unregister') + ' ' + serial,
        content: this.$t('Are you sure?'),
        positiveText: this.$t('OK'),
        negativeText: this.$t('Cancel'),
        onPositiveClick: async () => {
          this.loadingBar.start();

          const attributes = await fetchUserAttributes();
          const params = {
            body: {
              userId: attributes.sub,
              serialNumber: serial
            }
          };
          try {
            const operation = post({apiName: 'Controller', path: '/unregister', options: params});
            await operation.response;
            this.loadingBar.finish();
            this.getControllers();
          } catch (error) {
            console.error(error);
            if (error.response) console.error(error.response);
            this.notification.error({duration: 10000, content: this.$t(error.response.body)});
            this.loadingBar.error();
          };
        },
        onNegativeClick: () => {}
      });
    },
    async getControllers() {
      this.loadingBar.start();
      try {
        const operation = get({apiName: 'Controller', path: '/list'});
        const {body} = await operation.response;
        const response = await body.json();
        this.controllers = response;
        this.loadingBar.finish();
        this.loading = false;
      } catch (error) {
        console.error(error);
        if (error.response) console.error(error.response);
        this.loadingBar.error();
        this.loading = false;
      }
    },
    async getLimit() {
      try {
        const operation = get({apiName: 'Controller', path: '/limit'});
        const {body} = await operation.response;
        const response = await body.json();
        this.limits = response;
      } catch (error) {
        console.error(error);
        if (error.response) console.error(error.response);
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
