<template>
  <template v-if="$route.name === 'servicelinked' || $route.name === 'vpnconf'">
    <n-loading-bar-provider>
      <router-view />
    </n-loading-bar-provider>
  </template>

  <authenticator v-else :services="services">
    <template #header>
      <div style="padding: var(--amplify-space-medium); text-align: center;">
        <a href="/">
          <img
            class="amplify-image logoLogin"
            alt="nomos system"
            src="./assets/logo2.svg"
          >
        </a>
      </div>
    </template>

    <template #footer>
      <div class="terms-footer">
        <n-a href="http://nomos-system.com/redirect/?id=terms-and-conditions" target="_blank">
          {{ $t('Terms and Conditions') }}
        </n-a>
        <n-a href="http://nomos-system.com/redirect/?id=privacy-policy" target="_blank">
          {{ $t('Privacy Policy') }}
        </n-a>
      </div>
    </template>

    <template #sign-up-fields>
      <authenticator-sign-up-form-fields />
      <amplify-text-field
        :label="$t('Surname')"
        autocomplete="false"
        :placeholder="$t('Enter your Surname')"
        name="family_name"
        :hideLabel="false"
      />
      <amplify-check-box :errorMessage="validationErrors.acknowledgement" />
    </template>

    <template #default="{/**user,*/ signOut}">
      <n-config-provider :theme-overrides="themeOverrides">
        <n-notification-provider>
          <n-message-provider closable keep-alive-on-hover :duration="5000" :max="1">
            <n-dialog-provider>
              <n-loading-bar-provider>
                <n-layout has-sider>
                  <n-layout-sider :collapsed="siderCollapsed" :on-update:collapsed="onSiderCollapsed" collapse-mode="width" :show-trigger="true" collapsed-trigger-style="top: 200px;" trigger-style="top: 200px;" content-style="min-height: 100vh;" :bordered="true" :collapsed-width="50" width="240">
                    <n-space size="large" justify="space-between" vertical style="height: 100%">
                      <div>
                        <div style="text-align: center;">
                          <img class="logoSider" alt="nomos system" src="./assets/logo.png">
                        </div>
                        <main-menu :signOut="signOut" />
                      </div>

                      <div class="terms">
                        <n-a href="http://nomos-system.com/redirect/?id=terms-and-conditions" target="_blank">
                          {{ $t('Terms and Conditions') }}
                        </n-a>
                        <n-a href="http://nomos-system.com/redirect/?id=privacy-policy" target="_blank">
                          {{ $t('Privacy Policy') }}
                        </n-a>
                      </div>
                    </n-space>
                  </n-layout-sider>

                  <n-layout :position="isMobile ? 'absolute' : 'static'" :content-style="contentStyle">
                    <!-- component matched by the route will render here -->
                    <router-view />
                  </n-layout>
                </n-layout>
              </n-loading-bar-provider>
            </n-dialog-provider>
          </n-message-provider>
        </n-notification-provider>
      </n-config-provider>
    </template>
  </authenticator>
</template>

<script setup>
import MainMenu from './ui/Menu';

import {
  Authenticator,
  AuthenticatorSignUpFormFields,
  // Vue Composable to get access to validation errors
  useAuthenticator,
  // Amplify UI Primitives to simplify the custom fields
  AmplifyCheckBox,
  AmplifyTextField
} from '@aws-amplify/ui-vue';
import '@aws-amplify/ui-vue/styles.css';
import {toRefs} from 'vue';
const {validationErrors} = toRefs(useAuthenticator());

const services = {
  async validateCustomSignUp(formData) {
    if (!formData.acknowledgement) {
      return {
        acknowledgement: window.$t('You must agree to the Terms and Conditions')
      };
    }
  },
};

/**
 * Use this for type hints under js file
 * @type import('naive-ui').GlobalThemeOverrides
 */
const themeOverrides = {
  common: {
    primaryColor: '#c36',
    primaryColorHover: '#fb93a1',
    primaryColorPressed: '#fb93a1',
    primaryColorSuppl: '#fb93a1',
  },
  // Button: {
  //   textColor: '#FF0000'
  // },
  // Select: {
  //   peers: {
  //     InternalSelection: {
  //       textColor: '#FF0000'
  //     }
  //   }
  // }
};
</script>

<script>
export default {
  name: 'App',
  components: {MainMenu},
  data() {
    return {
      isMobile: false,
      siderCollapsed: false,
      auth: null
    };
  },
  computed: {
    contentStyle() {
      if (this.isMobile) return 'padding-left: 60px; padding-right: 20px; padding-bottom: 30px;';
      return 'padding-left: 20px; padding-right: 20px; padding-bottom: 30px;';
    }
  },
  watch: {
    'auth.route'(to, from) {
      if (to !== 'authenticated') document.body.className = 'start';
      else document.body.className = '';
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  created() {
    this.auth = useAuthenticator();
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
      this.onResize(); // resize on open
    });
  },
  methods: {
    onSiderCollapsed(state) {
      this.siderCollapsed = state;
    },
    onResize() {
      if (window.innerWidth < 850) {
        this.siderCollapsed = true;
        this.isMobile = true;
      } else {
        this.siderCollapsed = false;
        this.isMobile = false;
      }
    }
  }
};
</script>

<style>
:root {
  --primaryColor: #c36;
  --secondaryColor: #f7ecdd;
  --thirdColor: #fb93a1;
  --amplify-components-fieldcontrol-border-radius: 8px;
  --amplify-components-button-hover-border-color: #888;
  --amplify-components-button-active-background-color: #f7ecdd;
  --amplify-components-button-focus-background-color: #f7ecdd;
  --amplify-components-button-hover-background-color: #f7ecdd;
  --amplify-components-button-link-active-background-color: #fff;
  --amplify-components-button-link-focus-background-color: #fff;
  --amplify-components-button-link-hover-background-color: #fff;
  --amplify-components-tabs-item-color: #999;
  --amplify-components-tabs-item-active-border-color: #000;
  --amplify-components-tabs-item-active-color: #000;
  --amplify-components-tabs-item-hover-color: #000;
  --amplify-components-checkbox-button-color: #fff;
  --amplify-components-checkbox-icon-background-color: #fb93a1;
}
body.start {
  background-color: var(--secondaryColor);
}
.amplify-input[name="username"], .amplify-input[name="confirmation_code"] {
  border-start-start-radius: 8px !important;
  border-end-start-radius: 8px !important;
}
[data-amplify-authenticator] [data-amplify-router] {
  border-radius: 8px;
}
.amplify-button--primary {
  background: linear-gradient(90deg, rgba(90,2,147,1) 0%, rgba(138,149,209,1) 100%);
  border: none;
}
.amplify-button--small {
  color: #000;
  background: none;
}
.amplify-tabs__list--top {
  margin-left: 5px;
  margin-right: 5px;
}
.amplify-button--disabled {
  color: #ddd;
}
.n-layout-sider {
  background-color: var(--secondaryColor);
  z-index: 2;
}
.terms-footer {
  padding: var(--amplify-space-medium);
  text-align: center;
  a {
    color: var(--primaryColor);
    text-decoration-color: var(--primaryColor);
    padding: 1px;
    display: block;
  }
}
.terms {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 250px;
  text-align: left;
  background: none;
  display: block;
  padding: 10px;
  opacity: 1;
  visibility: visible;
  height: auto;
  transition: all 2s;
  color: var(--primaryColor);
  text-decoration-color: var(--primaryColor);
}
.terms a {
  display: block;
}
.n-layout-sider--collapsed {
  .terms {
    opacity: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    transition: all 0s;
  }
}
.n-menu-item-content.n-menu-item-content--selected::before {
  background: none !important;
}
.n-menu-item-content--selected {
  background-color: #f1f1f1 !important;
}
.n-menu .n-menu-item-content.n-menu-item-content--selected .n-menu-item-content__icon, .n-menu .n-menu-item-content.n-menu-item-content--selected .n-menu-item-content-header, .n-menu .n-menu-item-content.n-menu-item-content--selected .n-menu-item-content-header a {
  color: var(--primaryColor) !important;
}

@font-face {
  font-family: Degular;
  src: url('~@/assets/font/Degular-Regular.woff');
}
@font-face {
  font-family: Degular;
  src: url('~@/assets/font/Degular-Semibold.woff');
  font-weight: bold;
}
#app {
  font-family: Degular, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
}
.logoLogin {
  width: 40%;
  height: 4rem;
}
.logoSider {
  width: 50%;
  max-width: 4rem;
  margin-top: 10px;
}
</style>
