<template>
  <div class="servicelink">
    <img class="logo" alt="nomos system" src="../assets/logo2.svg">
    <h1>{{ $t('Your %name% account has been successfully connected!', {name: name}) }}</h1>
    <h2>{{ $t('You can close this window now.') }}</h2>
    <n-divider />
  </div>
</template>

<script>
import {useLoadingBar} from 'naive-ui';
export default {
  name: 'ServiceLinkedPanel',
  data() {
    return {
      name: ''
    };
  },
  async created() {
    this.loadingBar = useLoadingBar();
  },
  mounted() {
    this.name = this.$route.query.name || 'nomos system';
    this.loadingBar.start();

    setTimeout(() => {
      this.loadingBar.finish();
      window.close();

      try {
        // inform inappbrowser
        // eslint-disable-next-line
        (webkit || window.webkit).messageHandlers.cordova_iab.postMessage(JSON.stringify({state: 'cloudServiceLinked'}));
      } catch (e) {} // eslint-disable-line no-empty
    }, 4444);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.servicelink {
  padding: 20px;
}
.logo {
  height: 3rem;
}
</style>
