// https://docs.amplify.aws/start/getting-started/setup/q/integration/vue/#initialize-a-new-backend
import {Amplify} from 'aws-amplify';
import {I18n} from 'aws-amplify/utils';
import {fetchAuthSession} from 'aws-amplify/auth';
import Controller from './components/Controller.vue';
import Integrator from './components/Integrator.vue';
import User from './components/User.vue';
import ServiceLinked from './components/ServiceLinked.vue';
import VPNConf from './components/VPNConf.vue';
import {createRouter, createWebHashHistory} from 'vue-router';
import {
  // create naive ui
  create,
  // components
  NConfigProvider,
  NA,
  NSteps,
  NStep,
  NBadge,
  NAlert,
  NSkeleton,
  NLayout,
  NLayoutSider,
  NLayoutFooter,
  NImage,
  NIcon,
  NButton,
  NSwitch,
  NMenu,
  NLoadingBarProvider,
  NPageHeader,
  NGrid,
  NGi,
  NStatistic,
  NAvatar,
  NEmpty,
  NForm,
  NFormItem,
  NSelect,
  NInput,
  NInputNumber,
  NInputGroup,
  NSpace,
  NCard,
  NModal,
  NDialogProvider,
  NMessageProvider,
  NNotificationProvider,
  NDivider,
  NTable,
  NTag,
  NPopover
} from 'naive-ui';
// https://docs.amplify.aws/start/getting-started/setup/q/integration/vue/#install-amplify-libraries
import {createApp} from 'vue';
import App from './App.vue';
import {translations} from '@aws-amplify/ui-vue';

// Amplify configuration
import amplifyconfig from './amplifyconfiguration.json';
Amplify.configure(amplifyconfig);
const existingConfig = Amplify.getConfig();
// REST API's (https://docs.amplify.aws/lib/restapi/getting-started/q/platform/js/#manual-setup-import-existing-rest-api)
Amplify.configure({
  ...existingConfig,
  API: {
    REST: {
      // VUE_APP_API_URL is a environment variable and is enabled on Amplify "Build-Settings" page
      Controller: {
        endpoint: process.env.VUE_APP_API_URL + '/controller'
      },
      Users: {
        endpoint: process.env.VUE_APP_API_URL + '/users'
      },
      Other: {
        endpoint: process.env.VUE_APP_API_URL + '/other'
      }
    }
  }
});
Amplify.configure(Amplify.getConfig(), {
  API: {
    REST: {
      // Authorization header for all requests
      headers: async () => {
        const authToken = (await fetchAuthSession()).tokens?.accessToken?.toString();
        return {Authorization: authToken};
      }
    }
  }
});

// user locale/language
const userLocale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;

// i18n/localization
I18n.putVocabularies(translations);
I18n.setLanguage(userLocale);
I18n.putVocabulariesForLanguage('de', require('./lang/de.json'));
const translationPlugin = { // our global translation plugin
  install() {
    window.$t = app.config.globalProperties.$t = (text, vars) => {
      if (!vars) vars = {};
      text = I18n.get(text);
      Object.keys(vars).map((name) => {
        text = text.replace('%' + name + '%', vars[name]);
        return text;
      });
      return text;
    };
  }
};

// vue-router
const routes = [
  {path: '/', redirect: '/controller'},
  {path: '/home', redirect: '/controller'},
  {path: '/controller', component: Controller, name: 'controller'},
  {path: '/integrator', component: Integrator, name: 'integrator'},
  {path: '/user', component: User, name: 'user'},
  {path: '/servicelinked', name: 'servicelinked', component: ServiceLinked},
  {path: '/vpnconf', name: 'vpnconf', component: VPNConf}
];
const router = createRouter({
  history: createWebHashHistory(),
  routes
});

// naive-ui components
const naive = create({
  components: [NConfigProvider, NA, NSteps, NStep, NBadge, NAlert, NSkeleton, NLayout, NLayoutSider, NLayoutFooter, NImage, NIcon, NButton, NSwitch, NMenu, NLoadingBarProvider, NPageHeader, NGrid, NGi, NStatistic, NAvatar, NEmpty, NForm, NFormItem, NSelect, NInput, NInputNumber, NInputGroup, NSpace, NCard, NModal, NDialogProvider, NMessageProvider, NNotificationProvider, NDivider, NTable, NTag, NPopover]
});

const app = createApp(App);
app.use(router);
app.use(naive);
app.use(translationPlugin);
app.mount('#app');
