<template>
  <div class="user">
    <h1>{{ $t('Your User Account') }}</h1>

    <n-form v-if="user" size="large" :model="userAttributes" :rules="rules" label-placement="left" label-width="auto">
      <n-form-item :label="$t('Username')" path="username">
        <n-tag>{{ user.username }}</n-tag>
      </n-form-item>
      <n-form-item :label="$t('Name')" path="name" :required="true">
        <n-input v-model:value="userAttributes['name']" :placeholder="$t('Name')" />
      </n-form-item>
      <n-form-item :label="$t('Surname')" path="family_name" :required="true">
        <n-input v-model:value="userAttributes['family_name']" :placeholder="$t('Surname')" />
      </n-form-item>
      <n-form-item :label="$t('E-Mail')" path="email" :required="true">
        <n-input v-model:value="userAttributes['email']" :placeholder="$t('E-Mail')" />
      </n-form-item>
      <n-form-item :label="$t('Phone')">
        <n-input v-model:value="userAttributes['phone_number']" :placeholder="$t('Phone')" :status="validationError === 'phone' ? 'error' : ''" />
      </n-form-item>
      <n-form-item :label="$t('Street')">
        <n-input v-model:value="userAttributes['custom:street']" :placeholder="$t('Street')" />
      </n-form-item>
      <n-form-item :label="$t('Zipcode')">
        <n-input-number v-model:value="userAttributes['custom:zcode']" :show-button="false" :placeholder="$t('Zipcode')" />
      </n-form-item>
      <n-form-item :label="$t('City')">
        <n-input v-model:value="userAttributes['custom:city']" :placeholder="$t('City')" />
      </n-form-item>
      <n-form-item :label="$t('Country')">
        <n-select v-model:value="userAttributes['custom:country']" :placeholder="$t('Country')" :options="countries" />
      </n-form-item>
      <n-form-item label=" ">
        <n-button type="primary" @click="updateUser">
          {{ $t('Update') }}
        </n-button>
      </n-form-item>
    </n-form>

    <n-divider />

    <h1>{{ $t('Password Update') }}</h1>
    <n-form v-if="user" size="large" :model="passwordData" :rules="rules" label-placement="left" label-width="auto">
      <n-form-item :label="$t('Old Password')" :required="true">
        <n-input v-model:value="passwordData.oldPassword" type="password" :placeholder="$t('Old Password')" />
      </n-form-item>
      <n-form-item :label="$t('New Password')" :required="true">
        <n-input v-model:value="passwordData.newPassword" type="password" :placeholder="$t('New Password')" />
      </n-form-item>
      <n-form-item :label="$t('Re-enter Password')" :required="true">
        <n-input v-model:value="passwordData.newPassword2" type="password" :placeholder="$t('Re-enter Password')" />
      </n-form-item>
      <n-form-item label=" ">
        <n-button type="primary" :disabled="passwordFormInvalid" @click="updatePassword">
          {{ $t('Update') }}
        </n-button>
      </n-form-item>
    </n-form>

    <n-divider />

    <h1>{{ $t('Multi-Factor Authentication') }}</h1>
    <n-space vertical>
      <n-switch :rail-style="railStyle" :round="false" size="large" :value="mfa.preferred === 'TOTP'" :on-update:value="(v) => setMFA('totp', v)">
        <template #checked>
          {{ $t('Time-based One-time Password Authentication enabled') }}
        </template>
        <template #unchecked>
          {{ $t('Enable Time-based One-time Password Authentication') }}
        </template>
      </n-switch>

      <n-popover trigger="hover" :disabled="userAttributes.phone_number !== ''">
        <template #trigger>
          <n-switch :rail-style="railStyle" :round="false" :disabled="userAttributes.phone_number === ''" size="large" :value="mfa.preferred === 'SMS'" :on-update:value="(v) => setMFA('sms', v)">
            <template #checked>
              {{ $t('SMS Authentication enabled') }}
            </template>
            <template #unchecked>
              {{ $t('Enable SMS Authentication') }}
            </template>
          </n-switch>
        </template>
        <span>{{ $t('Missing phone number.') }}</span>
      </n-popover>

      <!--<n-button size="large" type="warning" :disabled="!mfa.preferred" @click="setMFA('NOMFA', true)">
        {{ $t('Disable Multi-Factor Authentication') }}
      </n-button>-->
    </n-space>

    <n-divider />

    <h1>{{ $t('Delete User Account') }}</h1>
    <n-button type="error" :loading="deleteUserInProgress" @click="deleteUser">
      <template #icon>
        <n-icon>
          <alert-circle-sharp />
        </n-icon>
      </template>
      {{ $t('Delete User Account') }}
    </n-button>

    <n-modal v-model:show="showEmailVerificationModal" preset="dialog" :close-on-esc="false" :title="$t('E-Mail Verification')" :mask-closable="false" :positive-text="$t('Verify')" :negative-text="$t('Cancel')" @positive-click="verifyEmail" @negative-click="userAttributes['email'] = originalEmail; showEmailVerificationModal = false">
      <p>{{ $t('Type in E-Mail confirmation code.') }}</p>
      <n-input v-model:value="emailVerificationCode" :placeholder="$t('Code')" minlength="6" maxlength="6" :style="{'max-width': '33%'}" :autofocus="true" />
    </n-modal>

    <n-modal v-model:show="shopTOTPVerificationCode" preset="dialog" :on-after-leave="() => shopTOTPVerificationCode = false" :title="$t('TOTP Verification')" :mask-closable="false" :positive-text="$t('Verify')" :negative-text="$t('Cancel')" @positive-click="verifyTOTP" @negative-click="shopTOTPVerificationCode = false">
      <qrcode-vue :size="200" :value="totpVerificationCode" />
      <p>{{ $t('Authentication key') }}</p>
      <n-input-group>
        <n-input :value="totpSharedSecret" disabled />
        <n-button type="default" @click="copyIntoClipboard">
          {{ $t('Copy') }}
        </n-button>
      </n-input-group>
      <p>{{ $t('Type in One-time password.') }}</p>
      <n-input v-model:value="challengeAnswer" :placeholder="$t('Code')" minlength="6" maxlength="6" :style="{'max-width': '33%'}" :autofocus="true" />
    </n-modal>
  </div>
</template>

<script>
import {post} from 'aws-amplify/api';
import {getCurrentUser, fetchUserAttributes, verifyTOTPSetup, setUpTOTP, updateMFAPreference, fetchMFAPreference, updateUserAttributes, deleteUser, updatePassword, confirmUserAttribute} from 'aws-amplify/auth';
import {useLoadingBar, useNotification, useMessage, useDialog} from 'naive-ui';
import {getData} from 'country-list';
import QrcodeVue from 'qrcode.vue';
import {AlertCircleSharp} from '@vicons/ionicons5';

function padWithZeroes(number, length) {
  let str = '' + number;
  while (str.length < length) {
    str = '0' + str;
  }
  return str;
}

export default {
  name: 'UserPanel',
  components: {QrcodeVue, AlertCircleSharp},
  data() {
    return {
      validationError: '',
      deleteUserInProgress: false,
      passwordData: {},
      emailVerificationCode: null,
      showEmailVerificationModal: false,
      challengeAnswer: null,
      shopTOTPVerificationCode: false,
      totpVerificationCode: null,
      totpSharedSecret: '',
      mfa: {},
      user: null,
      userAttributes: {},
      originalEmail: null,
      rules: {
        name: {
          required: true,
          trigger: 'blur',
          message: this.$t('Please input your name')
        },
        family_name: {
          required: true,
          trigger: 'blur',
          message: this.$t('Please input your surname')
        },
        email: {
          required: true,
          trigger: 'blur',
          message: this.$t('Please input your e-mail')
        }
      }
    };
  },
  computed: {
    passwordFormInvalid() {
      if (!this.passwordData.oldPassword) return true;
      else if (!this.passwordData.newPassword) return true;
      else if (!this.passwordData.newPassword2) return true;
      else if (this.passwordData.newPassword !== this.passwordData.newPassword2) return true;
      return false;
    },
    countries() {
      return getData().map((c) => {
        return {label: c.name, value: c.code};
      }).sort((a, b) => {
        const nameA = a.label.toLowerCase();
        const nameB = b.label.toLowerCase();
        if (nameA < nameB) return -1; // sort string ascending
        if (nameA > nameB) return 1;
        return 0; // default return value (no sorting)
      });
    }
  },
  async created() {
    this.dialog = useDialog();
    this.loadingBar = useLoadingBar();
    this.notification = useNotification();
    this.message = useMessage();
    this.user = await getCurrentUser();
    this.mfa = await fetchMFAPreference();
    this.userAttributes = await fetchUserAttributes();
    if (!this.userAttributes.phone_number) this.userAttributes.phone_number = '';
    if (!this.userAttributes['custom:street']) this.userAttributes['custom:street'] = '';
    if (!this.userAttributes['custom:city']) this.userAttributes['custom:city'] = '';
    if (!this.userAttributes['custom:country']) this.userAttributes['custom:country'] = '';
    if (this.userAttributes['custom:zcode']) this.userAttributes['custom:zcode'] = Number(this.userAttributes['custom:zcode']); // cast zipcode to number
    this.originalEmail = this.userAttributes.email;
  },
  methods: {
    async updateUser() {
      this.loadingBar.start();
      this.validationError = '';

      try {
        // parse/format phone number
        if (this.userAttributes.phone_number) {
          this.userAttributes.phone_number = this.userAttributes.phone_number.replace(/ /g, '');
          if (this.userAttributes.phone_number.startsWith('00')) {
            this.userAttributes.phone_number = '+' + this.userAttributes.phone_number.substr(2);
          }
        }

        const data = {
          name: this.userAttributes.name,
          family_name: this.userAttributes.family_name,
          email: this.userAttributes.email,
          phone_number: this.userAttributes.phone_number,
          'custom:street': this.userAttributes['custom:street'],
          'custom:zcode': this.userAttributes['custom:zcode'],
          'custom:city': this.userAttributes['custom:city'],
          'custom:country': this.userAttributes['custom:country']
        };
        if (!data['custom:zcode']) data['custom:zcode'] = '';
        else data['custom:zcode'] = data['custom:zcode'].toString(); // in cognito zipcode is a string

        await updateUserAttributes({userAttributes: data});
        this.notification.success({duration: 10000, content: this.$t('Successfully updated your user account.')});
        this.loadingBar.finish();

        // email has been changed?
        if (data.email !== this.originalEmail) {
          this.emailVerificationCode = '';
          this.showEmailVerificationModal = true;
        }
      } catch (e) {
        this.loadingBar.error();
        this.notification.error({duration: 10000, content: this.$t(e.message)});
        if (e.message.includes('Invalid phone number')) this.validationError = 'phone';
        console.error(e);
      }
    },
    deleteUser() {
      this.dialog.error({
        title: this.$t('Delete User Account'),
        content: this.$t('Are you sure?'),
        positiveText: this.$t('OK'),
        negativeText: this.$t('Cancel'),
        onPositiveClick: async () => {
          this.loadingBar.start();

          this.deleteUserInProgress = true;
          this.dialog.destroyAll();
          const attributes = await fetchUserAttributes();
          const params = {
            body: {
              userId: attributes.sub
            }
          };

          try {
            const operation = post({apiName: 'Users', path: '/remove', options: params});
            await operation.response;
            await deleteUser();
            this.loadingBar.finish();
          } catch (e) {
            console.error(e);
            this.notification.error({duration: 10000, content: this.$t(e.message)});
            this.loadingBar.error();
            this.deleteUserInProgress = false;
          };
        },
        onNegativeClick: () => {}
      });
    },
    async updatePassword() {
      this.loadingBar.start();
      try {
        await updatePassword({oldPassword: this.passwordData.oldPassword, newPassword: this.passwordData.newPassword});
        this.notification.success({duration: 10000, content: this.$t('Successfully updated password.')});
        this.loadingBar.finish();
        this.passwordData.oldPassword = ''; // reset
        this.passwordData.newPassword = '';
        this.passwordData.newPassword2 = '';
        return true;
      } catch (e) {
        console.error(e);
        this.notification.error({duration: 10000, content: this.$t(e.message.replace('Password did not conform with policy: ', ''))});
        this.loadingBar.error();
        return false;
      }
    },
    async verifyEmail() {
      this.loadingBar.start();
      try {
        await confirmUserAttribute({userAttributeKey: 'email', confirmationCode: this.emailVerificationCode.toString()});
        this.user = await fetchUserAttributes(); // retrieve new user data with updated email
        this.notification.success({duration: 10000, content: this.$t('Successfully updated E-Mail.')});
        this.loadingBar.finish();
        return true;
      } catch (e) {
        console.error(e);
        if (e.message === 'confirmation code is required to confirmUserAttribute') e.message = 'Confirmation code missing';
        this.notification.error({duration: 10000, content: this.$t(e.message)});
        this.loadingBar.error();
        return false;
      }
    },
    async setMFA(type, value) {
      if (type === 'totp' && value === true) {
        return this.setUpTOTP();
      }

      this.loadingBar.start();
      try {
        let payload = {};
        payload[type] = value ? 'PREFERRED' : 'NOT_PREFERRED';
        if (!value) payload = {totp: 'DISABLED', sms: 'DISABLED'};
        await updateMFAPreference(payload);
        this.mfa = await fetchMFAPreference(); // retrieve new user settings
        this.notification.success({duration: 10000, content: this.$t('Successfully updated MFA settings.')});
        this.loadingBar.finish();
        return true;
      } catch (e) {
        console.error(e);
        this.notification.error({duration: 10000, content: this.$t(e.message)});
        this.loadingBar.error();
        return false;
      }
    },
    async setUpTOTP() {
      try {
        // To setup TOTP, first you need to get a `authorization code` from Amazon Cognito
        const totp = await setUpTOTP();
        this.totpVerificationCode = totp.getSetupUri().href;
        this.totpSharedSecret = totp.sharedSecret;
        this.challengeAnswer = '';
        this.shopTOTPVerificationCode = true;
        this.loadingBar.finish();
        return true;
      } catch (e) {
        console.error(e);
        this.notification.error({duration: 10000, content: this.$t(e.message)});
        this.loadingBar.error();
        return false;
      }
    },
    async verifyTOTP() {
      try {
        if (!this.challengeAnswer) return false;
        // Then you will have your TOTP account in your TOTP-generating app (like Google Authenticator)
        // Use the generated one-time password to verify the setup
        await verifyTOTPSetup({code: padWithZeroes(this.challengeAnswer.toString(), 6)});
        this.loadingBar.finish();
        this.setMFA('totp', this.challengeAnswer); // try again / instead of bool we send a string to bypass function switch
        return true;
      } catch (e) {
        console.error(e);
        this.notification.error({duration: 10000, content: this.$t(e.message)});
        this.loadingBar.error();
        return false;
      }
    },
    railStyle({focused, checked}) {
      return {width: '420px', 'max-width': '80vw', 'white-space': 'nowrap', overflow: 'hidden', 'text-overflow': 'ellipsis', color: '#000 !important', background: checked ? '#2080f0' : '#d03050'};
    },
    copyIntoClipboard() {
      navigator.clipboard.writeText(this.totpSharedSecret);
      this.message.info(this.$t('Copied into clipboard'), {closable: true, duration: 4000});
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
