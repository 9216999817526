<template>
  <div class="vpnc">
    <img class="logo" alt="nomos system" src="../assets/logo2.svg">
    <h1>{{ $t(textTemplate, {serial: serial}) }}</h1>
    <h2>{{ $t('You can close this window now.') }}</h2>
    <n-divider />
  </div>
</template>

<script>
import {useLoadingBar} from 'naive-ui';
export default {
  name: 'VPNConfPanel',
  data() {
    return {
      serial: '',
      statusCode: 0
    };
  },
  computed: {
    textTemplate() {
      switch (this.statusCode) {
        case 0:
          return 'Serial number %serial% has been declined';
        case 1:
          return 'Serial number %serial% has been confirmed';
        case 2:
          return 'Serial number %serial% aleady confirmed';
        case 3:
          return 'Serial number %serial% aleady declined';
        default:
          return 'Invalid request';
      }
    }
  },
  async created() {
    this.loadingBar = useLoadingBar();
  },
  mounted() {
    this.serial = this.$route.query.sid || this.$t('Unknown');
    if (this.$route.query.status !== undefined) this.statusCode = Number(this.$route.query.status);
    this.loadingBar.start();

    setTimeout(() => {
      this.loadingBar.finish();
      window.close();
    }, 4444);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vpnc {
  padding: 20px;
}
.logo {
  height: 3rem;
}
</style>
