<template>
  <n-menu v-model:value="activeKey" mode="vertical" :options="menuOptions" :on-update:value="menuUpdate" />
</template>

<script>
import {h} from 'vue';
import {get} from 'aws-amplify/api';
import {RouterLink} from 'vue-router';
import {NIcon} from 'naive-ui';
import {
  // AppsOutline,
  PeopleOutline,
  CubeOutline,
  LogOutOutline,
  PersonCircleOutline
} from '@vicons/ionicons5';

function renderIcon(icon) {
  return () => h(NIcon, null, {default: () => h(icon)});
}

export default {
  name: 'MainMenu',
  props: {
    signOut: {
      default: () => {},
      type: Function
    }
  },
  data() {
    return {
      isIntegrator: false,
      activeKey: null
    };
  },
  computed: {
    menuOptions() {
      const options = [{
        label: () => h(RouterLink, {
          to: {
            name: 'controller',
            params: {}
          }
        }, {default: () => this.$t('Controllers')}),
        key: 'controller',
        icon: renderIcon(CubeOutline)
      },
      // {
      // label: () => h(RouterLink, {
      //   to: {
      //     name: 'licenses',
      //     params: {view: 'list'}
      //   }
      // }, {default: () => this.$t('Subscriptions')}),
      // key: 'licenses',
      // icon: renderIcon(AppsOutline)
      // },
      {
        label: () => h(RouterLink, {
          to: {
            name: 'user',
            params: {}
          }
        }, {default: () => this.$t('User')}),
        key: 'user',
        icon: renderIcon(PeopleOutline)
      }, {
        label: () => h(
          'a',
          {
            onclick: this.signOut
          },
          this.$t('Logout')
        ),
        key: 'logout',
        icon: renderIcon(LogOutOutline)
      }];

      if (this.isIntegrator) {
        options.unshift({
          label: () => h(RouterLink, {
            to: {
              name: 'integrator',
              params: {}
            }
          }, {default: () => this.$t('Integrator')}),
          key: 'integrator',
          icon: renderIcon(PersonCircleOutline)
        });
      }

      return options;
    }
  },
  mounted() {
    this.getIntegratorInfo();

    const getKeyInterval = setInterval(() => {
      this.activeKey = this.$router.currentRoute._value.name;
      if (this.activeKey) clearInterval(getKeyInterval);
    }, 50);
  },
  methods: {
    async getIntegratorInfo() {
      try {
        const operation = get({apiName: 'Users', path: '/integrator/get'});
        const {body} = await operation.response;
        const response = await body.json();
        this.isIntegrator = response.enabled;
      } catch (error) {};
    },
    menuUpdate(key) {
      this.activeKey = key;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
