import {get, post} from 'aws-amplify/api';

export default {
  data() {
    return {
      currencies: [{
        label: 'CHF',
        value: 'chf'
      }, {
        label: 'EUR',
        value: 'eur'
      }, {
        label: 'USD',
        value: 'usd'
      }],
      loadingCurrency: true,
      currency: ''
    };
  },
  computed: {
    currencySymbol() {
      switch (this.currency) {
        case 'eur':
          return '€';
        case 'usd':
          return '$';
      }
      return this.currency.toUpperCase();
    }
  },
  mounted() {
    this.getUserCurrency();
  },
  methods: {
    getCurrencySymbol(currency) {
      switch (currency) {
        case 'eur':
          return '€';
        case 'usd':
          return '$';
      }
      return currency.toUpperCase();
    },
    async getUserCurrency() {
      try {
        const operation = get({apiName: 'Users', path: '/currency/get'});
        const {body} = await operation.response;
        const response = await body.json();
        this.currency = response.currency;
        this.loadingCurrency = false;
      } catch (error) {
        if (error.response) console.error(error.response);
        this.loadingCurrency = false;
      };
    },
    async setUserCurrency(val, callback) {
      this.loadingBar.start();
      this.loadingCurrency = true;
      try {
        const params = {
          body: {
            currency: val
          }
        };
        const operation = post({apiName: 'Users', path: '/currency/set', options: params});
        await operation.response;
        this.currency = val;
        this.loadingBar.finish();
        this.loadingCurrency = false;
        if (typeof callback === 'function') callback(null);
      } catch (error) {
        if (error.response) console.error(error.response);
        if (error.response.body && typeof error.response.body.message === 'string') this.notification.error({duration: 10000, content: this.$t(error.response.data.message)});
        else if (typeof error.response.body === 'string') this.notification.error({duration: 10000, content: this.$t(error.response.body)});
        this.subscriptionAutoRenewLoading = false;
        this.loadingBar.error();
        this.loadingCurrency = false;
        if (typeof callback === 'function') callback(error);
      };
    },
  }
};
