<template>
  <div class="integrator">
    <h1>{{ $t('Integrator') }}</h1>

    <n-alert v-if="!setupDone" :title="$t('Warning')" type="warning">
      <p>{{ $t('One-time setup is required!') }}</p>
      <n-steps :current="currentSetupStep">
        <n-step :title="$t('Currency')">
          <p>{{ $t('Please Select') }}</p>
          <n-select v-if="currentSetupStep === 1" v-model:value="currency" :on-update:value="updateCurrency" :loading="loadingCurrency" :options="currencies" :placeholder="$t('Please Select')" style="width: 80px" />
        </n-step>
        <n-step :title="$t('Payment Details')">
          <n-button v-if="currentSetupStep === 2" type="primary" :loading="setupLoading" @click="setup">
            {{ $t('One-time Setup') }}
          </n-button>
        </n-step>
      </n-steps>
    </n-alert>

    <h2>{{ $t('VPN') }}</h2>
    <n-form size="large" label-placement="left" label-width="auto">
      <n-form-item :label="$t('Partner Name')">
        <n-tag v-if="!loadingInfos">
          {{ partnerName }}
        </n-tag>
        <n-skeleton v-else size="small" text width="9%" />
      </n-form-item>
    </n-form>
    <n-table v-if="!loadingClients">
      <thead>
        <tr>
          <th>{{ $t('Connection') }}</th>
          <th>{{ $t('Serialnumber') }}</th>
          <th>{{ $t('Description') }}</th>
          <th>{{ $t('Added') }}</th>
          <th width="120" />
        </tr>
      </thead>
      <tbody v-if="vpnClients.length">
        <tr v-for="client in vpnClients" :key="client.sid">
          <td>
            <n-icon :color="client.connected ? 'green' : 'red'">
              <link-icon v-if="!client.connected" />
              <un-link-icon v-else />
            </n-icon>
          </td>
          <td>{{ client.sid }}</td>
          <td><n-input v-model:value="client.name" :on-focus="() => {preventTableRefresh = true}" :on-blur="() => {preventTableRefresh = false}" :on-change="(val) => {onClientUpdate(client, val);}" :loading="updatingLoading === client.sid" :disabled="!client.confirmed || updatingLoading === client.sid" size="small" type="text" placeholder="" /></td>
          <td>{{ dateFormat(client.issued) }}</td>
          <td>
            <n-button v-if="!client.confirmed" secondary type="primary" size="small" :loading="confirmLoading === client.sid" @click="confirm(client)">
              {{ $t('Confirm') }}
            </n-button>
            <n-button v-else secondary type="primary" size="small" :loading="confirmLoading === client.sid" @click="decline(client)">
              {{ $t('Decline') }}
            </n-button>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="5">
            <n-empty :description="$t('No controllers registered.')" />
          </td>
        </tr>
      </tbody>
    </n-table>
    <template v-else>
      <n-skeleton size="large" text width="100%" />
      <n-skeleton size="large" text width="100%" />
      <n-skeleton size="large" text width="100%" />
    </template>

    <h2>{{ $t('Subscriptions') }}</h2>
    <n-table v-if="!loadingSubscriptions">
      <thead>
        <tr>
          <th>{{ $t('Status') }}</th>
          <th>{{ $t('Date') }}</th>
          <th>{{ $t('Description') }}</th>
          <th width="120" />
        </tr>
      </thead>
      <tbody v-if="subscriptions.length">
        <tr v-for="sub in subscriptions" :key="sub.number">
          <td>{{ $t(sub.status) }}</td>
          <td>{{ dateFormat(sub.created) }}</td>
          <td>{{ sub.name }}</td>
          <td />
        </tr>
        <tr v-if="!hasVPNsubscription">
          <td>{{ $t('inactive') }}</td>
          <td />
          <td>nomos system VPN Service</td>
          <td>
            <n-button secondary type="primary" size="small" @click="linkService({serviceLink: 'sra'}, false)">
              {{ $t('Setup') }}
            </n-button>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="5">
            <n-empty :description="$t('No subscriptions available.')" />
          </td>
        </tr>
      </tbody>
    </n-table>
    <template v-else>
      <n-skeleton size="large" text width="100%" />
      <n-skeleton size="large" text width="100%" />
      <n-skeleton size="large" text width="100%" />
    </template>

    <h2>{{ $t('Invoices') }}</h2>
    <n-table v-if="!loadingInvoices">
      <thead>
        <tr>
          <th>{{ $t('Status') }}</th>
          <th>{{ $t('Date') }}</th>
          <th>{{ $t('Number') }}</th>
          <th>{{ $t('Amount') }}</th>
          <th width="120" />
        </tr>
      </thead>
      <tbody v-if="invoices.length">
        <tr v-for="invoice in invoices" :key="invoice.number">
          <td>{{ invoiceStatus(invoice.status) }}</td>
          <td>{{ dateFormat(invoice.effective) }}</td>
          <td>{{ invoice.number }}</td>
          <td>{{ invoice.total }} {{ getCurrencySymbol(invoice.currency) }}</td>
          <td>
            <n-button secondary type="primary" size="small" @click="downloadInvoice(invoice)">
              {{ $t('Download') }}
            </n-button>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="5">
            <n-empty :description="$t('No invoices available.')" />
          </td>
        </tr>
      </tbody>
    </n-table>
    <template v-else>
      <n-skeleton size="large" text width="100%" />
      <n-skeleton size="large" text width="100%" />
      <n-skeleton size="large" text width="100%" />
    </template>
  </div>
</template>

<script>
import {DateTime} from 'luxon';
import {get, post} from 'aws-amplify/api';
import {fetchUserAttributes} from 'aws-amplify/auth';
import {useLoadingBar, useNotification, useMessage, useDialog} from 'naive-ui';
import {Unlink as LinkIcon, Link as UnLinkIcon} from '@vicons/ionicons5';
import CurrencyMixin from '../mixins/currency';

export default {
  name: 'IntegratorPanel',
  components: {LinkIcon, UnLinkIcon},
  mixins: [CurrencyMixin],
  data() {
    return {
      currentSetupStep: 1,
      setupDone: true,
      loadingInfos: true,
      loadingClients: true,
      loadingInvoices: true,
      loadingSubscriptions: true,
      preventTableRefresh: false,
      setupLoading: false,
      confirmLoading: '',
      updatingLoading: '',
      vpnClients: [],
      invoices: [],
      subscriptions: [],
      partnerName: ''
    };
  },
  computed: {
    hasVPNsubscription() {
      return this.subscriptions.find((s) => {
        return s.id === 'sra';
      });
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  created() {
    this.dialog = useDialog();
    this.loadingBar = useLoadingBar();
    this.message = useMessage();
    this.notification = useNotification();
  },
  mounted() {
    if (this.$route.query.view === 'canceled') {
      this.notification.warning({duration: 10000, content: this.$t('Setup canceled')});
    } else if (this.$route.query.view === 'success') {
      this.message.loading(this.$t('Waiting for confirmation...'), {closable: false, duration: 0});
      this.waitTillUpdated('Setup success', 'Setup could not be determined');
    }

    this.getInfo(false, () => {
      // link system manager service (when setup done)
      if (this.setupDone && this.$route.query.serviceLink === 'sm' && this.$route.query.serviceId) {
        this.linkService(this.$route.query, true);
      }
    });
    this.getVPNClients();
    this.interval = setInterval(this.getVPNClients, 5000);
    this.getSubscriptions();
    this.getInvoices();
  },
  methods: {
    dateFormat(date) {
      const f = {year: 'numeric', month: 'numeric', day: 'numeric'};
      if (Number.isInteger(date)) return DateTime.fromMillis(date).toLocaleString(f);
      return DateTime.fromISO(date).toLocaleString(f);
    },
    waitTillUpdated(successText, errorText) {
      const WAIT_TIME = 2000;

      this.getInfo(true, () => {
        if (this.setupDone) {
          // finished waiting for setup
          this.message.destroyAll();
          this.notification.success({duration: 10000, content: this.$t(successText)});
          this.loadingInfos = false;
        } else {
          // wait max 30sec.
          this.sucessWaitTime += WAIT_TIME;
          if (this.sucessWaitTime >= 30000) {
            this.message.destroyAll();
            this.notification.error({duration: 10000, content: this.$t(errorText)});
            this.loadingInfos = false;
            return; // done
          }

          // try again
          setTimeout(() => {
            this.waitTillUpdated(successText, errorText);
          }, WAIT_TIME);
        }
      });
    },
    async getInfo(keepLoading, callback) {
      try {
        const operation = get({apiName: 'Users', path: '/integrator/get'});
        const {body} = await operation.response;
        const response = await body.json();
        this.partnerName = response.partnerName;
        this.setupDone = response.setupDone || false;
        if (!keepLoading) this.loadingInfos = false;
        if (callback) callback(null);
      } catch (error) {
        if (error.response) console.error(error.response);
        if (!keepLoading) this.loadingInfos = false;
        if (callback) callback(error);
      };
    },
    async getVPNClients() {
      if (this.preventTableRefresh) return; // skip if focus on input field in table
      try {
        const operation = get({apiName: 'Users', path: '/integrator/vpnclients'});
        const {body} = await operation.response;
        const response = await body.json();
        if (Array.isArray(response)) this.vpnClients = response;
        this.loadingClients = false;
      } catch (error) {
        if (error.response) console.error(error.response);
        this.loadingClients = false;
      };
    },
    async getSubscriptions() {
      try {
        const operation = get({apiName: 'Users', path: '/integrator/subscriptions'});
        const {body} = await operation.response;
        const response = await body.json();
        if (Array.isArray(response)) this.subscriptions = response;
        this.loadingSubscriptions = false;
      } catch (error) {
        if (error.response) console.error(error.response);
        this.loadingSubscriptions = false;
      };
    },
    async getInvoices() {
      try {
        const operation = get({apiName: 'Users', path: '/integrator/invoices'});
        const {body} = await operation.response;
        const response = await body.json();
        if (Array.isArray(response)) this.invoices = response;
        this.loadingInvoices = false;
      } catch (error) {
        if (error.response) console.error(error.response);
        this.loadingInvoices = false;
      };
    },
    downloadInvoice(invoice) {
      window.location.href = invoice.pdf;
    },
    invoiceStatus(status) {
      function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
      return this.$t(capitalizeFirstLetter(status));
    },
    async confirm(client) {
      this.confirmLoading = client.sid;
      this.loadingBar.start();
      try {
        const operation = get({apiName: 'Other', path: '/sra/confirm/' + client.sid + '/' + client.confirmToken + '?noredir=1'});
        const {body} = await operation.response;
        await body.text();
        this.loadingBar.finish();
        this.notification.success({duration: 10000, content: this.$t('Successfully confirmed Controller.')});
        this.confirmLoading = '';
        this.getVPNClients();
      } catch (error) {
        this.loadingBar.error();
        this.notification.error({duration: 10000, content: this.$t(error.response.body)});
        if (error.response) console.error(error.response);
        this.confirmLoading = '';
        this.getVPNClients();
      };
    },
    async decline(client) {
      this.confirmLoading = client.sid;
      this.loadingBar.start();
      try {
        const operation = get({apiName: 'Other', path: '/sra/decline/' + client.sid + '/' + client.confirmToken + '?noredir=1'});
        const {body} = await operation.response;
        await body.text();
        this.loadingBar.finish();
        this.notification.success({duration: 10000, content: this.$t('Successfully declined Controller.')});
        this.confirmLoading = '';
        this.getVPNClients();
      } catch (error) {
        this.loadingBar.error();
        this.notification.error({duration: 10000, content: this.$t(error.response.body)});
        if (error.response) console.error(error.response);
        this.confirmLoading = '';
        this.getVPNClients();
      };
    },
    async onClientUpdate(client, name) {
      this.updatingLoading = client.sid;
      this.preventTableRefresh = true;
      this.loadingBar.start();
      const params = {
        body: {
          sid: client.sid,
          name
        }
      };

      try {
        const operation = post({apiName: 'Users', path: '/integrator/vpnclients/update', options: params});
        await operation.response;
        client.name = name;
        this.loadingBar.finish();
        this.notification.success({duration: 10000, content: this.$t('Successfully updated Controller.')});
        this.preventTableRefresh = false;
        this.getVPNClients();
        this.updatingLoading = '';
      } catch (error) {
        this.loadingBar.error();
        this.notification.error({duration: 10000, content: this.$t(error.response.body)});
        if (error.response) console.error(error.response);
        this.preventTableRefresh = false;
        this.updatingLoading = '';
      };
    },
    async setup() {
      this.setupLoading = true;
      this.loadingBar.start();

      const attributes = await fetchUserAttributes();
      const params = {
        body: {
          success_url: document.location.origin + '/' + this.$router.resolve({name: 'integrator', query: {view: 'success'}}).href,
          cancel_url: document.location.origin + '/' + this.$router.resolve({name: 'integrator', query: {view: 'canceled'}}).href,
          userId: attributes.sub
        }
      };

      try {
        const operation = post({apiName: 'Users', path: '/integrator/setup', options: params});
        const {body} = await operation.response;
        const response = await body.json();
        this.loadingBar.finish();
        this.setupLoading = false;
        if (response.url) document.location.href = response.url;
      } catch (error) {
        if (error.response) console.error(error.response);
        if (error.response && error.response.body && typeof error.response.body.message === 'string') this.notification.error({duration: 10000, content: this.$t(error.response.data.message)});
        else if (error.response && typeof error.response.body === 'string') this.notification.error({duration: 10000, content: this.$t(error.response.body)});
        this.loadingBar.error();
        this.setupLoading = false;
      };
    },
    updateCurrency(e) {
      this.setUserCurrency(e, (err) => {
        if (!err) this.currentSetupStep++;
      });
    },
    async linkService(params, closeWindow) {
      this.loadingBar.start();
      try {
        const operation = post({apiName: 'Users', path: '/integrator/service/link', options: {body: params}});
        await operation.response;
        this.loadingBar.finish();
        if (closeWindow) {
          this.notification.success({duration: 10000, content: this.$t('Successfully linked Service. You can close this Window now.')});
        } else {
          this.notification.success({duration: 10000, content: this.$t('Successfully linked Service.')});
        }
        this.getSubscriptions();
      } catch (error) {
        if (error.response) console.error(error.response);
        if (error.response && error.response.body && typeof error.response.body.message === 'string') this.notification.error({duration: 10000, content: this.$t(error.response.data.message)});
        else if (error.response && typeof error.response.body === 'string') this.notification.error({duration: 10000, content: this.$t(error.response.body)});
        this.loadingBar.error();
      };
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
